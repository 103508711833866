import { API } from '../api';

const getNotifications = () => {
    return API.get('callcenter/callcenter/callcenter/get-notifications')
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.notifications;
            }

            return {};
        })
};

const logout = () => {
    return API.post('callcenter/callcenter/callcenter/logout');
};

const updateProfilePhoto = (formData) => {
    API.post('callcenter/callcenter/callcenter/update-images', { data: formData }, { 'Content-Type': 'multipart/form-data' })
}

const getAgendaLinks = (groupId, clientId) => {
    return API.get('callcenter/callcenter/callcenter/get-links', { groupId: groupId, clientId: clientId })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data
            }

            return {};
        })
}

const doRefreshPage = (type, id) => API.get('callcenter/callcenter/callcenter/refresh-agenda', { type: type, id: id }).then(response => API.isSuccess(response) ? response.data : false);

export const ApiClientService = {
    getAgendaLinks,
    getNotifications,
    logout,
    updateProfilePhoto,
    doRefreshPage
};