import callCenter from './callcenter/callcenter.middleware';
import client from './client/client.middleware';
import group from './group/group.middleware';
import notifications from './notifications/notifications.middleware';
import patient from './patient/patient.middleware';
import settings from './settings/settings.middleware';
import schemaData from './schema-data/schema-data.middleware';

export default [
    callCenter,
    client,
    group,
    notifications,
    schemaData,
    patient,
    settings
]