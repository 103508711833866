import React from 'react';
import Spinner from '../reactstrap/Spinner';

class TableRowLoader extends React.Component {
    render() {
        const { loading, colSpan } = this.props;

        if (!loading) {
            return null;
        }

        return (
            <tr>
                <td colSpan={colSpan}>
                    <Spinner visible={true} />
                </td>
            </tr>
        );
    }
}

export default TableRowLoader;
