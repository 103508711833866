import React from 'react';
import { t } from 'i18next';
import swal from 'sweetalert';
import { LocalStorageService } from './../services/local-storage.service.js';

class Popup extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true;
        this.showMessage();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return null;
    }

    showMessage = () => {
        const popupMessage = LocalStorageService.get('PopupMessage');

        if (popupMessage) {
            LocalStorageService.remove('PopupMessage');

            let title = popupMessage.title ? popupMessage.title : '';
            let message = popupMessage.message;

            swal({
                title: title,
                text: message,
                icon: 'warning',
                buttons: {
                    confirm: {
                        text: t('common:Confirm'),
                        value: true,
                        visible: true,
                        className: 'bg-primary btn-info',
                        closeModal: true
                    }
                }
            })
        }
    }
}

export default Popup;