/**
 * Schema data actions
 */
export const SCHEMA_DATA_LOADED = 'SCHEMA_DATA_LOADED';
export function schemaDataLoaded(value) {
    return { type: SCHEMA_DATA_LOADED, value };
};

export const SCHEMA_DATA_UNLOADED = 'SCHEMA_DATA_UNLOADED';
export function schemaDataUnloaded() {
    return { type: SCHEMA_DATA_UNLOADED };
};

export const SCHEMA_DATA_UPDATED = 'SCHEMA_DATA_UPDATED';
export function schemaDataUpdated(key, id, value) {
    return { type: SCHEMA_DATA_UPDATED, key, id, value };
};

export const SCHEMA_DATA_REMOVED = 'SCHEMA_DATA_REMOVED';
export function schemaDataRemoved(key, id) {
    return { type: SCHEMA_DATA_REMOVED, key, id };
};