import { API } from '../api';

const getById = (id) => {
    return API.get('callcenter/schedule/schedule/get-schedule', { id: id })
        .then((response) => {
            if (API.isSuccess(response)) {
                return response.data;
            }

            throw new Error('error');
        });
};

const getByClient = (id = null, groupId = null) => {
    return API.get('callcenter/schedule/schedule/get-by-client', { client: id, group: groupId })
        .then((response) => {
            if (API.isSuccess(response)) {
                return response.data;
            }

            return [];
        });
};

const loadSchemaData = () => {
    return API.get('callcenter/schedule/schedule/load-schema-data')
        .then((response) => {
            if (API.isSuccess(response)) {
                return response.data;
            }

            return [];
        });
};

const getFreeSlotCount = (start, end, id, type) => {
    return API.get('callcenter/schedule/schedule/get-free-slot-count', { start: start, end: end, id: id, type: type })
        .then((response) => {
            if (API.isSuccess(response)) {
                return response.data;
            }
        })
        .catch((error) => { });
};

const saveBlocks = (blocks, clientId = null) => {
    return API.post('callcenter/schedule/block/set-blocks', { data: { blocks: blocks, client: clientId } })
        .then(
            response => {
                if (response.status === 'success') {
                    return true;
                }

                throw new Error(response.data);
            }
        );
};

const composeSlots = (scheduleId, data) => {
    return API.post('callcenter/schedule/slot/compose-slots', { schedule: scheduleId, data: data })
        .then((response) => {
            if (API.isSuccess(response)) {
                return true;
            }

            return false;
        });
};

const updateSlotCategory = (slotId, categoryId, start, end) => {
    return API.post('callcenter/schedule/slot/update-category', {
        data: {
            category: categoryId,
            slotId: slotId,
            start: start,
            end: end
        }
    })
        .then((response) => {
            return response;
        });
}

const getPeriodsBySchedule = (scheduleId) => {
    return API.get('callcenter/schedule/period/get-by-schedule', { id: scheduleId })
        .then((response) => {
            if (API.isSuccess(response)) {
                return response.data.periods;
            }

            return [];
        });
};

const updatePriorityBreakHours = (scheduleId, data) => {
    return API.post('callcenter/schedule/slot/update-priority-breakhours', { schedule: scheduleId, data: { slots: data } })
        .then((response) => {
            if (API.isSuccess(response)) {
                return true;
            }

            throw new Error('error');
        });
};

const findFirstAvailableSlotForAppointment = (appointmentId) => {
    return API.get('callcenter/schedule/schedule/find-first-available-slot', { id: appointmentId })
        .then((response) => {
            if (API.isSuccess(response)) {
                return response.data;
            }

            return [];
        });
}

export const ApiScheduleService = {
    getById,
    getByClient,
    loadSchemaData,
    getFreeSlotCount,
    saveBlocks,
    composeSlots,
    getPeriodsBySchedule,
    updatePriorityBreakHours,
    updateSlotCategory,
    findFirstAvailableSlotForAppointment
};