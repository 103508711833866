export default function componentLoader(lazyComponent, attemptsLeft = 5) {
    return new Promise((resolve, reject) => {
        lazyComponent()
            .then(resolve)
            .catch((error) => {
                setTimeout(() => {
                    if (attemptsLeft === 1) {
                        reject(error);
                        return;
                    }

                    componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
                }, 1000);
            })
    });
};