import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions';

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import MenuOffsidebar from '../../menu/MenuOffsidebar';

class Offsidebar extends Component {

    state = {
        activeTab: 'settings',
        offsidebarReady: false
    }

    componentDidMount() {
        // When mounted display the offsidebar
        this.setState({ offsidebarReady: true });
    }

    toggle = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    toggleSidebar = () => {
        this.props.actions.toggleSetting('offsidebarOpen');
    }

    handleSettingCheckbox = event => {
        this.props.actions.changeSetting(event.target.name, event.target.checked);
    }

    render() {

        return (
            this.state.offsidebarReady &&
            <aside className="offsidebar" id="offsidebar-settings">
                { /* START Off Sidebar (right) */}
                <nav>
                    <div>
                        { /* Nav tabs */}
                        <Nav tabs justified>
                            <NavItem>
                                <NavLink className={this.state.activeTab === 'settings' ? 'active' : ''}
                                    onClick={() => { this.toggle('settings'); }}
                                >
                                    <em className="icon-equalizer fa-lg"></em>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        { /* Tab panes */}
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="settings" className="px-2">
                                {
                                    MenuOffsidebar.map((item, i) => {
                                        return this.renderSection(item, i);
                                    })
                                }
                            </TabPane>
                        </TabContent>
                    </div>
                </nav>
                { /* END Off Sidebar (right) */}
            </aside>
        );
    }

    renderSection(item, i) {
        return (
            <React.Fragment key={i}>
                <h3 className="text-thin mt-2">{t(item.translate)}</h3>
                {
                    item.submenu.map((item, i) => {
                        return this.renderItem(item, i);
                    })
                }
            </React.Fragment>
        );
    }

    renderItem(item, i) {
        return (
            <div className="py-1" key={i}>
                <Link to={item.path} onClick={this.toggleSidebar}>{t(item.translate)}</Link>
            </div>
        );
    }

}

Offsidebar.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object,
    theme: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings, theme: state.theme })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Offsidebar);
