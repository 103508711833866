import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* loader component for Suspense*/
import PageLoader from './components/PageLoader';

import Base from './components/layout/Base';
import BasePage from './components/layout/BasePage';
import PrivateRoute from './routing/PrivateRoute';

import routes from './routing/routes';
import ErrorBoundary from './containers/Error/ErrorBoundary';
import componentLoader from './ComponentLoader';

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props} />;

const Dashboard = lazy(() => componentLoader(() => import('./containers/Dashboard/Dashboard')));

//agenda
const MyAgenda = lazy(() => componentLoader(() => import('./containers/Agenda/MyAgenda/MyAgenda')));
const GroupOverview = lazy(() => componentLoader(() => import('./containers/Agenda/Group/GroupOverview')));
const HomeVisit = lazy(() => componentLoader(() => import('./containers/Agenda/HomeVisit/HomeVisit')));
const SearchFreeSlot = lazy(() => componentLoader(() => import('./containers/Agenda/SearchFreeSlot/SearchFreeSlot')));
const History = lazy(() => componentLoader(() => import('./containers/Agenda/History/History')));
const DayAppointment = lazy(() => componentLoader(() => import('./containers/Agenda/DayAppointment/DayAppointment')));
const Conflict = lazy(() => componentLoader(() => import('./containers/Agenda/Conflict/Conflict')));
const Appointement = lazy(() => componentLoader(() => import('./containers/Agenda/Appointment/Appointment')));
const Note = lazy(() => componentLoader(() => import('./containers/Agenda/Note/Note')));

//patients
const Patient = lazy(() => componentLoader(() => import('./containers/Patient/Patient/Patient')));

// settings
const Exception = lazy(() => componentLoader(() => import('./containers/Settings/Exception/Exception')));
const Holiday = lazy(() => componentLoader(() => import('./containers/Settings/Holiday/Holiday')));
const Blocking = lazy(() => componentLoader(() => import('./containers/Settings/Blocking/Blocking')));

// Tools
const Popup = lazy(() => componentLoader(() => import('./containers/Tools/Popup/Popup')));
const Task = lazy(() => componentLoader(() => import('./containers/Tools/Task/Task')));
const Reminder = lazy(() => componentLoader(() => import('./containers/Tools/Reminder/Reminder')));

// Profile
const MailBox = lazy(() => componentLoader(() => import('./containers/Profile/Mailbox/Mailbox')));

// Communication
const Mail = lazy(() => componentLoader(() => import('./containers/Communication/Mail/Mail')));
const Sms = lazy(() => componentLoader(() => import('./containers/Communication/Sms/Sms')));

// Authentication
const Login = lazy(() => componentLoader(() => import('./containers/Pages/Login')));
const ForgotPassword = lazy(() => componentLoader(() => import('./containers/Pages/ForgotPassword')));
const ResetPassword = lazy(() => componentLoader(() => import('./containers/Pages/ResetPassword')));

const NotFound = lazy(() => componentLoader(() => import('./containers/Pages/NotFound')));
const ErrorPage = lazy(() => componentLoader(() => import('./containers/Pages/Error500')));

const Print = lazy(() => componentLoader(() => import('./containers/Pages/Print')));
const AutoRequest = lazy(() => componentLoader(() => import('./containers/Pages/AutoRequest')));

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
    /* See full project for reference */
    routes.auth.login,
    routes.auth.forgotpassword,
    routes.auth.resetpassword,
    //routes.auth.resetpasswordcode
];

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'
    const animationName = ''; // 'rag-fadeIn'

    let isPage = false;
    listofPages.forEach(path => {
        if (location.pathname.indexOf(path) > -1) {
            isPage = true;
        }
    });

    if (
        // listofPages.indexOf(location.pathname) > -1 ||
        isPage ||
        location.pathname.includes('/print/') === true ||
        location.pathname.includes('/e/') === true
    ) {
        return (
            // Page Layout component wrapper
            <BasePage>
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        <Route path={routes.e.al} component={waitFor(AutoRequest)} />

                        {/* See full project for reference */}
                        <Route path={routes.auth.resetpasswordcode} component={waitFor(ResetPassword)} />
                        <Route path={routes.auth.resetpassword} component={waitFor(ResetPassword)} />
                        <Route path={routes.auth.forgotpassword} component={waitFor(ForgotPassword)} />
                        <Route path={routes.auth.login} component={waitFor(Login)} />
                        <PrivateRoute path={routes.appointments.print} component={waitFor(Print)} />
                        <PrivateRoute path={routes.agenda.printWeek} component={waitFor(Print)} />
                        <PrivateRoute path={routes.agenda.printDay} component={waitFor(Print)} />
                        <PrivateRoute path={routes.agenda.groupPrint} component={waitFor(Print)} />
                    </Switch>
                </Suspense>
            </BasePage>
        )
    } else {
        return (
            // Layout component wrapper
            <Base>
                <ErrorBoundary>
                    <TransitionGroup>
                        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                            <Suspense fallback={<PageLoader />}>
                                <Switch location={location}>
                                    <PrivateRoute exact path={routes.home} component={waitFor(Dashboard)} />
                                    <PrivateRoute exact path={routes.dashboard} component={waitFor(Dashboard)} />

                                    <PrivateRoute path={routes.agenda.appointmentCopy} exact component={waitFor(MyAgenda)} />
                                    <PrivateRoute path={routes.agenda.appointmentMove} exact component={waitFor(MyAgenda)} />
                                    <PrivateRoute path={routes.myagenda} component={waitFor(MyAgenda)} />
                                    <PrivateRoute path={routes.notes.overview} component={waitFor(Note)} />

                                    <PrivateRoute path={routes.agenda.appointmentMoveClient} exact component={waitFor(GroupOverview)} />
                                    <PrivateRoute path={routes.group} component={waitFor(GroupOverview)} />
                                    <PrivateRoute path={routes.subgroup} component={waitFor(GroupOverview)} />

                                    <PrivateRoute path={routes.homevisits.overview} component={waitFor(HomeVisit)} />

                                    <PrivateRoute path={routes.agenda.searchfreeslot} component={waitFor(SearchFreeSlot)} />
                                    <PrivateRoute path={routes.agenda.history} component={waitFor(History)} />
                                    <PrivateRoute path={routes.agenda.conflicts} component={waitFor(Conflict)} />

                                    <PrivateRoute path={routes.appointments.overview} component={waitFor(Appointement)} />

                                    <PrivateRoute path={routes.patients.overview} component={waitFor(Patient)} />

                                    <PrivateRoute path={routes.holidays.overview} component={waitFor(Holiday)} />

                                    <PrivateRoute path={routes.dayappointments.overview} component={waitFor(DayAppointment)} />

                                    <PrivateRoute path={routes.exceptions.overview} component={waitFor(Exception)} />

                                    <PrivateRoute path={routes.blockings.overview} component={waitFor(Blocking)} />

                                    <PrivateRoute path={routes.tasks.overview} component={waitFor(Task)} />
                                    <PrivateRoute path={routes.reminders.overview} component={waitFor(Reminder)} />
                                    <PrivateRoute path={routes.popups.overview} component={waitFor(Popup)} />

                                    <PrivateRoute path={routes.mailbox.inbox} component={waitFor(MailBox)} />

                                    <PrivateRoute path={routes.communication.mail.compose} component={waitFor(Mail)} />
                                    <PrivateRoute path={routes.communication.sms.compose} component={waitFor(Sms)} />

                                    <PrivateRoute path={routes.error} component={waitFor(ErrorPage)} />
                                    <PrivateRoute path={routes.notfound} component={waitFor(NotFound)} />

                                    <Redirect to={routes.notfound} />
                                </Switch>
                            </Suspense>
                        </CSSTransition>
                    </TransitionGroup>
                </ErrorBoundary>
            </Base>
        )
    }
};

export default withRouter(Routes);
