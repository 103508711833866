import moment from 'moment';

const create = (input = null, format = null) => {
    if (!input) {
        return moment();
    }

    if (format) {
        return moment(input, format);
    }

    return moment(input);
};

const format = (input, format = 'YYYY-MM-DD') => {
    if (!(input instanceof moment)) {
        input = moment(input);
    }

    return input.format(format);
};

export const UtilDate = {
    create,
    format
};