import React from 'react';
import { withRouter } from 'react-router';
import { t } from 'i18next';
import ContentWrapper from '../../components/layout/ContentWrapper';
import { Heading } from '../../components';
import { ApiLogService } from '../../services/api/log.service';
import { connect } from 'react-redux';
import { UtilDate } from '../../utils/date.util';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            error: null,
            errorInfo: null
        };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentWillMount() {
        this.unlisten = this.props.history.listen(() => {
            this.setState({ hasError: false, error: null, errorInfo: null });
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            hasError: true,
            error: error,
            errorInfo: errorInfo
        });

        console.info(error, errorInfo);

        this.sendError(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <ContentWrapper>
                    <Heading title={t('error:title')}></Heading>
                    <p>{t('error:description')}</p>
                    <p>
                        U kan eventueel volgenda acties ondernemen om verder te werken in de agenda:
                        <ul>
                            <li>Herlaad de pagina: <a href="#" onClick={this.refreshPage}>Klik hier om te herladen</a></li>
                            <li>Probeer opnieuw <a href="#" onClick={this.login}>in te loggen</a></li>
                            <li>Sluit de browser volledig af en start deze daarna opnieuw op</li>
                        </ul>
                    </p>
                </ContentWrapper>
            );
        }

        return this.props.children;
    }

    refreshPage = (e) => {
        e.preventDefault();
        window.location.reload();
    }

    sendError = (error) => {
        if (process.env.NODE_ENV === 'development') {
            return;
        }

        ApiLogService.log({
            subject: this.props.client ? this.props.client.fullName : '',
            error: error.toString(),
            stack: error.stack,
            time: UtilDate.create().format('YYYY-MM-DD HH:mm:ss')
        })
    }
}

const mapStateToProps = state => ({ client: state.client ? state.client : null })

export default connect(mapStateToProps)(withRouter(ErrorBoundary));