import React from 'react';
import PropTypes from 'prop-types';
import FlashMessage from '../FlashMessage';
import ModalGroupSelect from '../modals/ModalGroupSelect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions';
import Popup from '../Popup';

/**
 * Wrapper element for template content
 */
class ContentWrapper extends React.Component {
    componentDidMount() {
        this.props.actions.changeSetting('isCollapsedText', this.props.settings.wasCollapsedBeforeSettings);
    }

    render() {
        return (
            <div className={'content-wrapper ' + this.props.className} >
                <ModalGroupSelect {...this.props} />
                <FlashMessage />
                <Popup />

                {this.props.unwrap ?
                    (<div className="unwrap">{this.props.children}</div>)
                    :
                    (this.props.children)
                }
            </div >
        )
    }
}

ContentWrapper.propTypes = {
    /** add element with 'unwrap' class to expand content area */
    unwrap: PropTypes.bool
}
ContentWrapper.defaultProps = {
    unwrap: false
}

const mapStateToProps = state => ({ client: state.client ? state.client : null, settings: state.settings });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(ContentWrapper);
