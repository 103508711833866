import { CLIENT_SET, CLIENT_UPDATE, CLIENT_REMOVE, CLIENT_LOGGED_IN, CLIENT_LOGGED_OUT } from './client.actions';

const clientReducer = (state = null, action) => {
    switch (action.type) {
        case CLIENT_LOGGED_IN:
        case CLIENT_SET:
        case CLIENT_UPDATE:
            return action.value
        case CLIENT_LOGGED_OUT:
        case CLIENT_REMOVE:
            return null;
        default:
            return state;
    }
}

export default clientReducer;