/**
 * Call center actions
 */
export const CALLCENTER_INITIALS_SET = 'CALLCENTER_INITIALS_SET';
export const CALLCENTER_INITIALS_REMOVE = 'CALLCENTER_INITIALS_REMOVE';
export const CALLCENTER_ACCESS_KEY_SET = 'CALLCENTER_ACCESS_KEY_SET';
export const CALLCENTER_ACCESS_KEY_REMOVE = 'CALLCENTER_ACCESS_KEY_REMOVE';

export function callCenterInitialsSet(value) {
    return { type: CALLCENTER_INITIALS_SET, value };
};

export function callCenterInitialsRemove() {
    return { type: CALLCENTER_INITIALS_REMOVE };
};

export function callCenterAccessKeySet(value) {
    return { type: CALLCENTER_ACCESS_KEY_SET, value };
};

export function callCenterAccessKeyRemove() {
    return { type: CALLCENTER_ACCESS_KEY_REMOVE };
};